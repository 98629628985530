<template>
  <div>
    <!--面包屑-->
    <div class="breadcrumb">
      <el-button type="primary" :loading="loading" @click="enable">启用</el-button>
      <el-button @click="$router.go(-1)">取消</el-button>
    </div>
    <!--主体内容-->
    <div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="130px"
        label-position="right"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="content bgFFF">
              <div class="title">系统信息</div>
              <el-form-item label="商户名称:" prop="operationName">
                <el-input
                  maxlength="50"
                  show-word-limit
                  v-model="ruleForm.operationName"
                  placeholder="请输入商户名称"
                />
              </el-form-item>
              <el-form-item label="商户编码:" prop="operationCode">
                <el-input
                  show-word-limit
                  v-model="ruleForm.operationCode"
                  placeholder="请输入商户编码"
                />
              </el-form-item>
              <el-form-item label="商户类型:" prop="operationType">
                <el-select v-model="ruleForm.operationType">
                  <el-option label="普通" :value="1" />
                  <el-option label="平台" :value="2" />
                  <el-option label="清分" :value="3" />
                </el-select>
              </el-form-item>
              <template v-if="ruleForm.operationType === 3">
                <el-form-item label="商户层级:" prop="operationLevel">
                  <el-select v-model="ruleForm.operationLevel">
                    <el-option label="一级" :value="1" />
                    <el-option label="二级" :value="2" />
                  </el-select>
                </el-form-item>
              </template>
              <el-form-item label="经营业务类型:" prop="businessArray">
                <el-select multiple collapse-tags v-model="ruleForm.businessArray">
                  <el-option label="停车业务" value="1" />
                  <el-option label="加油业务" value="2" />
                  <el-option label="车务业务" value="3" />
                  <el-option label="充电业务" value="4" />
                </el-select>
              </el-form-item>
              <el-form-item label="商户有效期:" prop="timeArray">
                <el-date-picker
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  v-model="ruleForm.timeArray"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="content bgFFF">
              <div class="title">工商信息</div>
              <el-form-item label="公司名称:" prop="operationFullName">
                <el-input
                  maxlength="50"
                  show-word-limit
                  v-model="ruleForm.operationFullName"
                  placeholder="请输入公司名称"
                />
              </el-form-item>
              <el-form-item label="公司地址:" prop="areaArray">
                <el-cascader
                  :options="options"
                  :props="{ expandTrigger: 'hover' }"
                  v-model="ruleForm.areaArray"
                  placeholder="请选择 省/市/区"
                />
              </el-form-item>
              <el-form-item prop="operationAddress">
                <el-input
                  maxlength="50"
                  show-word-limit
                  v-model="ruleForm.operationAddress"
                  placeholder="请输入 街道/门牌 详细地址"
                />
              </el-form-item>
              <el-form-item label="联系人:" prop="contact">
                <el-input
                  maxlength="10"
                  show-word-limit
                  v-model="ruleForm.contact"
                  placeholder="请输入联系人姓名"
                />
              </el-form-item>
              <el-form-item label="联系电话:" prop="mobile">
                <el-input
                  maxlength="20"
                  show-word-limit
                  v-model="ruleForm.mobile"
                  placeholder="请输入联系电话"
                />
              </el-form-item>
              <el-form-item label="邮箱:" prop="email">
                <el-input
                  maxlength="50"
                  show-word-limit
                  v-model="ruleForm.email"
                  placeholder="请输入联系邮箱地址"
                />
              </el-form-item>
              <el-form-item label="营业执照:">
                <el-upload
                  action
                  list-type="picture-card"
                  :headers="uploadHeader"
                  :file-list="fileList"
                  :limit="1"
                  :http-request="handleFiles"
                  :before-upload="beforeUpload"
                  :on-change="handleOnChange"
                  :on-remove="handleRemove"
                  :class="{ hide: hideUploadEdit }"
                >
                  <i class="el-icon-plus"></i>
                  <div slot="tip" class="el-upload__tip">
                    （非必填）请上传营业执照扫描附件，大小5M以内，支持格式.jpg
                  </div>
                </el-upload>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="content bgFFF">
              <div class="title">账户信息</div>
              <el-form-item label="开户行名称:" prop="bankName">
                <el-input
                  maxlength="50"
                  show-word-limit
                  v-model="ruleForm.bankName"
                  placeholder="请输入开户行名称"
                />
              </el-form-item>
              <el-form-item label="开户行地址:" prop="bankAddress">
                <el-input
                  maxlength="50"
                  show-word-limit
                  v-model="ruleForm.bankAddress"
                  placeholder="请输入开户行地址"
                />
              </el-form-item>
              <el-form-item label="对公账户号:" prop="bankCard">
                <el-input
                  maxlength="30"
                  show-word-limit
                  v-model="ruleForm.bankCard"
                  placeholder="请输入对公账户号"
                />
              </el-form-item>
            </div>
            <div class="content bgFFF">
              <div class="title">清结算信息</div>
              <el-form-item label="支付收款帐户:" prop="accountType">
                <el-select v-model="ruleForm.accountType" clearable @change="handleChange">
                  <el-option label="爱泊车帐户" :value="1" />
                  <el-option label="特约商户帐户" :value="2" />
                  <el-option label="商户独立帐户" :value="3" />
                  <el-option label="农行清分账户" :value="4" />
                </el-select>
              </el-form-item>
              <!-- 爱泊车帐户 -->
              <template v-if="ruleForm.accountType === 1">
                <el-form-item label="清分比例（%）:" prop="clearProportion">
                  <el-input
                    maxlength="8"
                    show-word-limit
                    v-model="ruleForm.clearProportion"
                    placeholder="请输入清分给商户的资金比例（0.01~1000）"
                  />
                </el-form-item>
                <el-form-item label="清分周期（T+）:" prop="clearCycle">
                  <el-input
                    maxlength="4"
                    show-word-limit
                    v-model="ruleForm.clearCycle"
                    placeholder="请输入清分周期"
                  />
                </el-form-item>
                <el-form-item label="结算费率（%）:" prop="settlementRate">
                  <el-input
                    maxlength="8"
                    show-word-limit
                    v-model="ruleForm.settlementRate"
                    placeholder="请输入结算给商户的资金费率（0.01~1000）"
                  />
                </el-form-item>
                <el-form-item label="手续费承担方:" prop="bearSide">
                  <el-select v-model="ruleForm.bearSide">
                    <el-option :label="$t('searchModule.Merchant')" :value="1" />
                    <el-option label="爱泊车" :value="2" />
                  </el-select>
                </el-form-item>
                <el-form-item label="结算周期（T+）:" prop="settlementCycle">
                  <el-input
                    maxlength="4"
                    show-word-limit
                    v-model="ruleForm.settlementCycle"
                    placeholder="请输入结算周期"
                  />
                </el-form-item>
              </template>
              <!-- 特约商户帐户 -->
              <template v-if="ruleForm.accountType === 2">
                <el-row class="config-row">
                  <el-col :span="12">APP (微信支付)</el-col>
                  <el-col :span="12">
                    <el-button
                      v-if="!checkForm.wxMchIdByApp"
                      type="primary"
                      size="mini"
                      @click="checkConfig(1)"
                      >待配置</el-button
                    >
                    <el-button v-else type="success" size="mini" @click="checkConfig(1)"
                      >完成
                    </el-button>
                  </el-col>
                </el-row>
                <el-row class="config-row">
                  <el-col :span="12">APP (支付宝支付)</el-col>
                  <el-col :span="12">
                    <el-button
                      v-if="
                        !checkForm.aliApplicationId &&
                        !checkForm.aliThirdId &&
                        !checkForm.aliThirdIdKey &&
                        !checkForm.aliApiKey
                      "
                      type="primary"
                      size="mini"
                      @click="checkConfig(2)"
                      >待配置</el-button
                    >
                    <el-button v-else type="success" size="mini" @click="checkConfig(2)"
                      >完成
                    </el-button>
                  </el-col>
                </el-row>
                <el-row class="config-row">
                  <el-col :span="12">微信公众号及小程序</el-col>
                  <el-col :span="12">
                    <el-button
                      v-if="!checkForm.wxMchIdByMicro"
                      type="primary"
                      size="mini"
                      @click="checkConfig(3)"
                      >待配置</el-button
                    >
                    <el-button v-else type="success" size="mini" @click="checkConfig(3)"
                      >完成
                    </el-button>
                  </el-col>
                </el-row>
                <el-row class="config-row">
                  <el-col :span="12">支付宝生活号及小程序</el-col>
                  <el-col :span="12">
                    <el-button
                      v-if="
                        !checkForm.aliMicrApplicationId &&
                        !checkForm.aliMicrThirdId &&
                        !checkForm.aliMicrThirdIdKey &&
                        !checkForm.aliMicrApiKey
                      "
                      type="primary"
                      size="mini"
                      @click="checkConfig(4)"
                      >待配置</el-button
                    >
                    <el-button v-else type="success" size="mini" @click="checkConfig(4)"
                      >完成
                    </el-button>
                  </el-col>
                </el-row>
              </template>
              <!-- 商户独立帐户 -->
              <template v-if="ruleForm.accountType === 3">
                <el-row class="config-row">
                  <el-col :span="12">APP (微信支付)</el-col>
                  <el-col :span="12">
                    <el-button
                      v-if="
                        !checkForm.wxApplicationId &&
                        !checkForm.wxThirdId &&
                        !checkForm.wxApiKey &&
                        !checkForm.wxDeveKey
                      "
                      type="primary"
                      size="mini"
                      @click="checkConfig(5)"
                      >待配置</el-button
                    >
                    <el-button v-else type="success" size="mini" @click="checkConfig(5)"
                      >完成
                    </el-button>
                  </el-col>
                </el-row>
                <el-row class="config-row">
                  <el-col :span="12">APP (支付宝支付)</el-col>
                  <el-col :span="12">
                    <el-button
                      v-if="
                        !checkForm.aliApplicationId &&
                        !checkForm.aliThirdId &&
                        !checkForm.aliThirdIdKey &&
                        !checkForm.aliApiKey
                      "
                      type="primary"
                      size="mini"
                      @click="checkConfig(6)"
                      >待配置</el-button
                    >
                    <el-button v-else type="success" size="mini" @click="checkConfig(6)"
                      >完成
                    </el-button>
                  </el-col>
                </el-row>
                <el-row class="config-row">
                  <el-col :span="12">微信公众号及小程序</el-col>
                  <el-col :span="12">
                    <el-button
                      v-if="
                        !checkForm.wxMicroApplicationId &&
                        !checkForm.wxMicroThirdId &&
                        !checkForm.wxMicroApiKey &&
                        !checkForm.wxMicroDeveKey
                      "
                      type="primary"
                      size="mini"
                      @click="checkConfig(7)"
                      >待配置</el-button
                    >
                    <el-button v-else type="success" size="mini" @click="checkConfig(7)"
                      >完成
                    </el-button>
                  </el-col>
                </el-row>
                <el-row class="config-row">
                  <el-col :span="12">支付宝生活号及小程序</el-col>
                  <el-col :span="12">
                    <el-button
                      v-if="
                        !checkForm.aliMicrApplicationId &&
                        !checkForm.aliMicrThirdId &&
                        !checkForm.aliMicrThirdIdKey &&
                        !checkForm.aliMicrApiKey
                      "
                      type="primary"
                      size="mini"
                      @click="checkConfig(8)"
                      >待配置</el-button
                    >
                    <el-button v-else type="success" size="mini" @click="checkConfig(8)"
                      >完成
                    </el-button>
                  </el-col>
                </el-row>
              </template>
              <!-- 农行清分账户 -->
              <template v-if="ruleForm.accountType === 4">
                <el-form-item label="清分比例（%）:" prop="clearProportion">
                  <el-input
                    maxlength="8"
                    show-word-limit
                    v-model="ruleForm.clearProportion"
                    placeholder="请输入清分给商户的资金比例（0.01~1000）"
                  />
                </el-form-item>
              </template>
              <!-- 弹窗 -->
              <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
                <div slot="title" class="slot-title">{{ slotTitle }}</div>
                <el-form :model="settleForm" :rules="rules" ref="settleForm">
                  <!-- 特约商户帐户 -->
                  <!-- APP（微信支付） -->
                  <template v-if="slotType === 1">
                    <el-form-item label="微信子商户号:" label-width="200px" prop="wxMchIdByApp">
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.wxMchIdByApp"
                        placeholder="请输入微信子商户号"
                      />
                    </el-form-item>
                  </template>
                  <!-- APP（支付宝支付） -->
                  <template v-if="slotType === 2">
                    <el-form-item label="支付宝应用ID:" label-width="200px" prop="aliApplicationId">
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.aliApplicationId"
                        placeholder="请输入支付宝应用ID"
                      />
                    </el-form-item>
                    <el-form-item label="支付宝支付商户ID:" label-width="200px" prop="aliThirdId">
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.aliThirdId"
                        placeholder="请输入支付宝支付商户ID"
                      />
                    </el-form-item>
                    <el-form-item
                      label="支付宝支付商户私钥:"
                      label-width="200px"
                      prop="aliThirdIdKey"
                    >
                      <el-input
                        maxlength="2000"
                        show-word-limit
                        v-model="settleForm.aliThirdIdKey"
                        placeholder="请输入支付宝支付商户私钥"
                      />
                    </el-form-item>
                    <el-form-item label="支付宝公钥:" label-width="200px" prop="aliApiKey">
                      <el-input
                        maxlength="500"
                        show-word-limit
                        v-model="settleForm.aliApiKey"
                        placeholder="请输入支付宝公钥"
                      />
                    </el-form-item>
                  </template>
                  <!-- 微信公众号及小程序 -->
                  <template v-if="slotType === 3">
                    <el-form-item label="微信子商户号:" label-width="200px" prop="wxMchIdByMicro">
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.wxMchIdByMicro"
                        placeholder="请输入微信子商户号"
                      />
                    </el-form-item>
                  </template>
                  <!-- 支付宝生活号及小程序 -->
                  <template v-if="slotType === 4">
                    <el-form-item
                      label="支付宝应用ID:"
                      label-width="200px"
                      prop="aliMicrApplicationId"
                    >
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.aliMicrApplicationId"
                        placeholder="请输入支付宝应用ID"
                      />
                    </el-form-item>
                    <el-form-item
                      label="支付宝支付商户ID:"
                      label-width="200px"
                      prop="aliMicrThirdId"
                    >
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.aliMicrThirdId"
                        placeholder="请输入支付宝支付商户ID"
                      />
                    </el-form-item>
                    <el-form-item
                      label="支付宝支付商户私钥:"
                      label-width="200px"
                      prop="aliMicrThirdIdKey"
                    >
                      <el-input
                        maxlength="2000"
                        show-word-limit
                        v-model="settleForm.aliMicrThirdIdKey"
                        placeholder="请输入支付宝支付商户私钥"
                      />
                    </el-form-item>
                    <el-form-item label="支付宝公钥:" label-width="200px" prop="aliMicrApiKey">
                      <el-input
                        maxlength="500"
                        show-word-limit
                        v-model="settleForm.aliMicrApiKey"
                        placeholder="请输入支付宝公钥"
                      />
                    </el-form-item>
                  </template>
                  <!-- 商户独立帐户 -->
                  <!-- APP（微信支付） -->
                  <template v-if="slotType === 5">
                    <el-form-item
                      label="微信支付应用ID(开放平台):"
                      label-width="200px"
                      prop="wxApplicationId"
                    >
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.wxApplicationId"
                        placeholder="请输入微信支付应用ID(开放平台)"
                      />
                    </el-form-item>
                    <el-form-item label="微信支付商户ID:" label-width="200px" prop="wxThirdId">
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.wxThirdId"
                        placeholder="请输入微信支付商户ID"
                      />
                    </el-form-item>
                    <el-form-item label="微信支付APL_KEY:" label-width="200px" prop="wxApiKey">
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.wxApiKey"
                        placeholder="请输入微信支付APL_KEY"
                      />
                    </el-form-item>
                    <el-form-item label="微信支付开发者密钥:" label-width="200px" prop="wxDeveKey">
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.wxDeveKey"
                        placeholder="请输入微信支付开发者密钥"
                      />
                    </el-form-item>
                  </template>
                  <!-- APP（支付宝支付） -->
                  <template v-if="slotType === 6">
                    <el-form-item label="支付宝应用ID:" label-width="200px" prop="aliApplicationId">
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.aliApplicationId"
                        placeholder="请输入支付宝应用ID"
                      />
                    </el-form-item>
                    <el-form-item label="支付宝支付商户ID:" label-width="200px" prop="aliThirdId">
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.aliThirdId"
                        placeholder="请输入支付宝支付商户ID"
                      />
                    </el-form-item>
                    <el-form-item
                      label="支付宝支付商户私钥:"
                      label-width="200px"
                      prop="aliThirdIdKey"
                    >
                      <el-input
                        maxlength="2000"
                        show-word-limit
                        v-model="settleForm.aliThirdIdKey"
                        placeholder="请输入支付宝支付商户私钥"
                      />
                    </el-form-item>
                    <el-form-item label="支付宝公钥:" label-width="200px" prop="aliApiKey">
                      <el-input
                        maxlength="500"
                        show-word-limit
                        v-model="settleForm.aliApiKey"
                        placeholder="请输入支付宝公钥"
                      />
                    </el-form-item>
                  </template>
                  <!-- 微信公众号及小程序 -->
                  <template v-if="slotType === 7">
                    <el-form-item
                      label="微信支付应用ID(公众平台):"
                      label-width="200px"
                      prop="wxMicroApplicationId"
                    >
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.wxMicroApplicationId"
                        placeholder="请输入微信支付应用ID(公众平台)"
                      />
                    </el-form-item>
                    <el-form-item label="微信支付商户ID:" label-width="200px" prop="wxMicroThirdId">
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.wxMicroThirdId"
                        placeholder="请输入微信支付商户ID"
                      />
                    </el-form-item>
                    <el-form-item label="微信支付APL_KEY:" label-width="200px" prop="wxMicroApiKey">
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.wxMicroApiKey"
                        placeholder="请输入微信支付APL_KEY"
                      />
                    </el-form-item>
                    <el-form-item
                      label="微信支付开发者密钥:"
                      label-width="200px"
                      prop="wxMicroDeveKey"
                    >
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.wxMicroDeveKey"
                        placeholder="请输入微信支付开发者密钥"
                      />
                    </el-form-item>
                  </template>
                  <!-- 支付宝生活号及小程序 -->
                  <template v-if="slotType === 8">
                    <el-form-item
                      label="支付宝应用ID:"
                      label-width="200px"
                      prop="aliMicrApplicationId"
                    >
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.aliMicrApplicationId"
                        placeholder="请输入支付宝应用ID"
                      />
                    </el-form-item>
                    <el-form-item
                      label="支付宝支付商户ID:"
                      label-width="200px"
                      prop="aliMicrThirdId"
                    >
                      <el-input
                        maxlength="36"
                        show-word-limit
                        v-model="settleForm.aliMicrThirdId"
                        placeholder="请输入支付宝支付商户ID"
                      />
                    </el-form-item>
                    <el-form-item
                      label="支付宝支付商户私钥:"
                      label-width="200px"
                      prop="aliMicrThirdIdKey"
                    >
                      <el-input
                        maxlength="2000"
                        show-word-limit
                        v-model="settleForm.aliMicrThirdIdKey"
                        placeholder="请输入支付宝支付商户私钥"
                      />
                    </el-form-item>
                    <el-form-item label="支付宝公钥:" label-width="200px" prop="aliMicrApiKey">
                      <el-input
                        maxlength="500"
                        show-word-limit
                        v-model="settleForm.aliMicrApiKey"
                        placeholder="请输入支付宝公钥"
                      />
                    </el-form-item>
                  </template>
                </el-form>
                <span slot="footer" class="dialog-footer">
                  <el-button type="primary" :loading="loading" @click="saveSettleData"
                    >{{ $t('button.preservation') }}</el-button
                  >
                  <el-button @click="dialogVisible = false">取消</el-button>
                </span>
              </el-dialog>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    let checkCode = (rule, value, callback) => {
      if (!/^[a-z0-9]+$/i.test(value)) {
        callback(new Error("编码格式不正确!"));
      } else {
        callback();
      }
    };
    return {
      options: [],
      dialogVisible: false,
      slotTitle: "",
      slotType: 0,
      loading: false,
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      fileList: [],
      hideUploadEdit: false,
      image: [],
      formInline: {},
      ruleForm: {
        operationName: "",
        operationCode: "",
        operationType: 1,
        operationLevel: "",
        businessArray: [],
        timeArray: [],
        operationFullName: "",
        areaArray: [],
        operationAddress: "",
        contact: "",
        mobile: "",
        email: "",
        bankName: "",
        bankAddress: "",
        bankCard: "",
        clearProportion: "",
        clearCycle: "",
        settlementRate: "",
        bearSide: "",
        settlementCycle: "",
        accountType: "",
      },
      settleForm: {},
      checkForm: {},
      rules: {
        operationName: [{ required: true, message: "请输入商户名称", trigger: "blur" }],
        operationCode: [
          { required: true, message: "请输入商户编码", trigger: "blur" },
          { validator: checkCode, trigger: "blur" },
        ],
        operationType: [{ required: true, message: "请选择商户类型", trigger: "change" }],
        operationLevel: [{ required: true, message: "请选择商户层级", trigger: "change" }],
        businessArray: [{ required: true, message: "请选择经营业务类型", trigger: "change" }],
        timeArray: [{ required: true, message: "请选择商户有效期", trigger: "change" }],
        operationFullName: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
        areaArray: [{ required: true, message: "请选择 省/市/区", trigger: "change" }],
        operationAddress: [
          { required: true, message: "请输入 街道/门牌 详细地址", trigger: "blur" },
        ],
        contact: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { pattern: /(^1\d{10}$)|(^[0-9]\d{7}$)/, message: "联系电话格式不正确" },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
            message: "邮箱格式不正确",
          },
        ],
        bankName: [{ required: true, message: "请输入开户行名称", trigger: "blur" }],
        bankAddress: [{ required: true, message: "请输入开户行地址", trigger: "blur" }],
        bankCard: [{ required: true, message: "请输入对公账户号", trigger: "blur" }],
        accountType: [{ required: true, message: "请选择支付收款帐户", trigger: "change" }],
        clearProportion: [{ required: true, message: "请输入清分比例", trigger: "blur" }],
        clearCycle: [{ required: true, message: "请输入清分周期", trigger: "blur" }],
        settlementRate: [{ required: true, message: "请输入结算费率", trigger: "blur" }],
        bearSide: [{ required: true, message: "请选择手续费承担方", trigger: "change" }],
        settlementCycle: [{ required: true, message: "请输入结算周期", trigger: "blur" }],
        // 清结算信息
        wxMchIdByApp: [{ required: true, message: "请输入微信子商户号", trigger: "blur" }],
        aliApplicationId: [{ required: true, message: "请输入支付宝应用ID", trigger: "blur" }],
        aliThirdId: [{ required: true, message: "请输入支付宝支付商户ID", trigger: "blur" }],
        aliThirdIdKey: [{ required: true, message: "请输入支付宝支付商户私钥", trigger: "blur" }],
        aliApiKey: [{ required: true, message: "请输入支付宝公钥", trigger: "blur" }],
        wxMchIdByMicro: [{ required: true, message: "请输入微信子商户号", trigger: "blur" }],
        aliMicrApplicationId: [{ required: true, message: "请输入支付宝应用ID", trigger: "blur" }],
        aliMicrThirdId: [{ required: true, message: "请输入支付宝支付商户ID", trigger: "blur" }],
        aliMicrThirdIdKey: [{ required: true, message: "支付宝支付商户私钥", trigger: "blur" }],
        aliMicrApiKey: [{ required: true, message: "请输入支付宝公钥", trigger: "blur" }],
        wxApplicationId: [
          { required: true, message: "请输入微信支付应用ID(开放平台)", trigger: "blur" },
        ],
        wxThirdId: [{ required: true, message: "请输入微信支付商户ID", trigger: "blur" }],
        wxApiKey: [{ required: true, message: "请输入微信支付APL_KEY", trigger: "blur" }],
        wxDeveKey: [{ required: true, message: "请输入微信支付开发者密钥", trigger: "blur" }],
        wxMicroApplicationId: [
          { required: true, message: "请输入微信支付应用ID(公众平台)", trigger: "blur" },
        ],
        wxMicroThirdId: [{ required: true, message: "请输入微信支付商户ID", trigger: "blur" }],
        wxMicroApiKey: [{ required: true, message: "请输入微信支付APL_KEY", trigger: "blur" }],
        wxMicroDeveKey: [{ required: true, message: "请输入微信支付开发者密钥", trigger: "blur" }],
      },
      isEdit: this.$route.query.operationId !== undefined,
      operationId: this.$route.query.operationId,
      operationCode: "",
    };
  },
  mounted() {
    if (this.isEdit) {
      this.getOperatorDetail();
    }
    this.getAddress();
  },
  methods: {
    // 获取区域列表
    getAddress() {
      const opt = {
        url: "/acb/2.0/systems/loginUser/getUserAreaWithProvince",
        method: "get",
        data: {},
        success: (res) => {
          if (res.value.length === 0) {
            return;
          }
          const value = this.getFormattedList(res.value);
          this.options = value;
        },
      };
      this.$request(opt);
    },
    // 将区域数据格式重排
    getFormattedList(value) {
      value.forEach((item, index) => {
        const keyMap = {
          areaId: "value",
          areaName: "label",
          childrenAreas: "children",
        };
        for (const key in item) {
          const newKey = keyMap[key];
          if (newKey) {
            item[newKey] = item[key];
            delete item[key];
          }
        }
        if (item.children) {
          if (item.children.length === 0) {
            delete item.children;
            return;
          }
          this.getFormattedList(item.children);
        }
      });
      return value;
    },
    // 根据商户ID获取详情
    getOperatorDetail() {
      this.loading = true;
      const opt = {
        url: "/acb/2.0/operation/id/" + this.operationId,
        method: "get",
        success: (res) => {
          this.loading = false;
          const value = res.value;
          this.operationCode = value.operationCode;
          this.setForm(value);
          this.getImage(value.operationId);
        },
        fail: (res) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    // 将获取到的数据回显
    setForm(value) {
      this.ruleForm.operationName = value.operationName;
      this.ruleForm.operationType = value.operationType;
      this.ruleForm.operationLevel = value.operationLevel;
      this.ruleForm.businessArray = value.businessType ? value.businessType.split(",") : [];
      this.ruleForm.timeArray = [value.startTime, value.endTime];
      this.ruleForm.operationFullName = value.operationFullName;
      this.ruleForm.operationCode = value.operationCode;
      if (value.parentAreaId) {
        this.ruleForm.areaArray = [undefined, value.parentAreaId, value.areaId];
      } else {
        this.ruleForm.areaArray = [undefined, value.areaId];
      }
      this.ruleForm.operationAddress = value.operationAddress;
      this.ruleForm.contact = value.contact;
      this.ruleForm.mobile = value.mobile;
      this.ruleForm.email = value.email;
      this.ruleForm.bankName = value.bankName;
      this.ruleForm.bankAddress = value.bankAddress;
      this.ruleForm.bankCard = value.bankCard;
      this.ruleForm.accountType = value.accountType;
      // const cleariInfo = value.cleariInfo ? JSON.parse(value.cleariInfo) : {}
      if (value.cleariInfo) {
        let cleariInfo = JSON.parse(value.cleariInfo);
        switch (value.accountType) {
          case 1:
            this.ruleForm.clearProportion = cleariInfo.clearProportion;
            this.ruleForm.clearCycle = cleariInfo.clearCycle;
            this.ruleForm.settlementRate = cleariInfo.settlementRate;
            this.ruleForm.bearSide = cleariInfo.bearSide;
            this.ruleForm.settlementCycle = cleariInfo.settlementCycle;
            break;
          case 2:
            this.settleForm = cleariInfo;
            this.checkForm = cleariInfo;
            break;
          case 3:
            this.settleForm = cleariInfo;
            this.checkForm = cleariInfo;
            break;
          default:
            break;
        }
      }
    },
    // 根据商户ID查询图片
    getImage(operationId) {
      const tempData = { commonKey: operationId };
      const opt = {
        url: "/acb/2.0/systems/loginUser/getImageList",
        method: "post",
        data: tempData,
        success: (res) => {
          // if (res.value) {
          //   return
          // }
          if (res.value[1]) {
            this.fileList = [{ url: res.value[1] }];
            this.hideUploadEdit = true;
          } else {
            this.fileList = [];
          }
        },
      };
      this.$request(opt);
    },
    // 启用
    enable() {
      this.formInline = Object.assign({}, this.ruleForm);
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          const { accountType } = this.formInline;
          const {
            wxMchIdByApp,
            aliApplicationId,
            aliThirdId,
            aliThirdIdKey,
            aliApiKey,
            wxMchIdByMicro,
            aliMicrApplicationId,
            aliMicrThirdId,
            aliMicrThirdIdKey,
            aliMicrApiKey,
            wxApplicationId,
            wxThirdId,
            wxApiKey,
            wxDeveKey,
            wxMicroApplicationId,
            wxMicroThirdId,
            wxMicroApiKey,
            wxMicroDeveKey,
          } = this.checkForm;
          if (
            accountType === 2 &&
            !(
              wxMchIdByApp &&
              aliApplicationId &&
              aliThirdId &&
              aliThirdIdKey &&
              aliApiKey &&
              wxMchIdByMicro &&
              aliMicrApplicationId &&
              aliMicrThirdId &&
              aliMicrThirdIdKey &&
              aliMicrApiKey
            )
          ) {
            this.$alert("请将清结算信息填写完整", this.$t('pop_up.Tips'), { confirmButtonText: this.$t('pop_up.Determine') });
            return;
          } else if (
            accountType === 3 &&
            !(
              wxApplicationId &&
              wxThirdId &&
              wxApiKey &&
              wxDeveKey &&
              aliApplicationId &&
              aliThirdId &&
              aliThirdIdKey &&
              aliApiKey &&
              wxMicroApplicationId &&
              wxMicroThirdId &&
              wxMicroApiKey &&
              wxMicroDeveKey &&
              aliMicrApplicationId &&
              aliMicrThirdId &&
              aliMicrThirdIdKey &&
              aliMicrApiKey
            )
          ) {
            this.$alert("请将清结算信息填写完整", this.$t('pop_up.Tips'), { confirmButtonText: this.$t('pop_up.Determine') });
            return;
          } else if (accountType === 1) {
            let { clearProportion, clearCycle, settlementRate, settlementCycle } = this.ruleForm;
            clearProportion = Number(clearProportion);
            clearCycle = parseInt(clearCycle);
            settlementRate = Number(settlementRate);
            settlementCycle = parseInt(settlementCycle);
            if (clearProportion < 0.01 || clearProportion > 1000) {
              this.$alert("清分比例数值区间为0.01至1000", this.$t('pop_up.Tips'), { confirmButtonText: this.$t('pop_up.Determine') });
              return;
            }
            if (!/^[0-9]+?$/.test(clearCycle)) {
              this.$alert("清分周期格式不正确", this.$t('pop_up.Tips'), { confirmButtonText: this.$t('pop_up.Determine') });
              return;
            }
            if (settlementRate < 0.01 || settlementRate > 1000) {
              this.$alert("结算费率数值区间为0.01至1000", this.$t('pop_up.Tips'), { confirmButtonText: this.$t('pop_up.Determine') });
              return;
            }
            if (!/^[0-9]+?$/.test(settlementCycle)) {
              this.$alert("结算周期格式不正确", this.$t('pop_up.Tips'), { confirmButtonText: this.$t('pop_up.Determine') });
              return;
            }
          } else if (accountType === 4) {
            let { clearProportion } = this.ruleForm;
            clearProportion = Number(clearProportion);
            if (clearProportion < 0.01 || clearProportion > 1000) {
              this.$alert("清分比例数值区间为0.01至1000", this.$t('pop_up.Tips'), { confirmButtonText: this.$t('pop_up.Determine') });
              return;
            }
          }
          let tempData = Object.assign({}, this.formInline);
          tempData = this.handleData(tempData, this.checkForm, accountType);
          this.saveData(tempData);
        }
      });
    },
    // 处理将提交的数据
    handleData(tempData, checkForm, accountType) {
      const copyAreaArray = [...tempData.areaArray];
      tempData.areaId = copyAreaArray.pop();
      tempData.businessType = tempData.businessArray.join(",");
      tempData.startTime = this.$moment(tempData.timeArray[0]).format("YYYY-MM-DD");
      tempData.endTime = this.$moment(tempData.timeArray[1]).format("YYYY-MM-DD");
      let info = { accountType };
      switch (accountType) {
        case 1: {
          info.clearProportion = tempData.clearProportion;
          info.clearCycle = tempData.clearCycle;
          info.settlementRate = tempData.settlementRate;
          info.bearSide = tempData.bearSide;
          info.settlementCycle = tempData.settlementCycle;
          break;
        }
        case 2: {
          info.wxMchIdByApp = checkForm.wxMchIdByApp;
          info.aliApplicationId = checkForm.aliApplicationId;
          info.aliThirdId = checkForm.aliThirdId;
          info.aliThirdIdKey = checkForm.aliThirdIdKey;
          info.aliApiKey = checkForm.aliApiKey;
          info.wxMchIdByMicro = checkForm.wxMchIdByMicro;
          info.aliMicrApplicationId = checkForm.aliMicrApplicationId;
          info.aliMicrThirdId = checkForm.aliMicrThirdId;
          info.aliMicrThirdIdKey = checkForm.aliMicrThirdIdKey;
          info.aliMicrApiKey = checkForm.aliMicrApiKey;
          break;
        }
        case 3: {
          info.wxApplicationId = checkForm.wxApplicationId;
          info.wxThirdId = checkForm.wxThirdId;
          info.wxApiKey = checkForm.wxApiKey;
          info.wxDeveKey = checkForm.wxDeveKey;
          info.aliApplicationId = checkForm.aliApplicationId;
          info.aliThirdId = checkForm.aliThirdId;
          info.aliThirdIdKey = checkForm.aliThirdIdKey;
          info.aliApiKey = checkForm.aliApiKey;
          info.wxMicroApplicationId = checkForm.wxMicroApplicationId;
          info.wxMicroThirdId = checkForm.wxMicroThirdId;
          info.wxMicroApiKey = checkForm.wxMicroApiKey;
          info.wxMicroDeveKey = checkForm.wxMicroDeveKey;
          info.aliMicrApplicationId = checkForm.aliMicrApplicationId;
          info.aliMicrThirdId = checkForm.aliMicrThirdId;
          info.aliMicrThirdIdKey = checkForm.aliMicrThirdIdKey;
          info.aliMicrApiKey = checkForm.aliMicrApiKey;
          break;
        }
        case 4: {
          info.clearProportion = tempData.clearProportion;
          break;
        }
        default:
          break;
      }
      delete tempData.clearProportion;
      delete tempData.clearCycle;
      delete tempData.settlementRate;
      delete tempData.bearSide;
      delete tempData.settlementCycle;
      delete tempData.areaArray;
      delete tempData.businessArray;
      delete tempData.timeArray;
      tempData.cleariInfo = JSON.stringify(info);
      return tempData;
    },
    // 保存
    saveData(tempData) {
      // console.log('tempData-->', tempData, this.isEdit);
      tempData.operationState = 1;
      if (this.isEdit) {
        tempData.operationId = this.operationId;
        tempData.operationCode = this.operationCode;
      }
      this.loading = true;
      const opt = {
        url: this.isEdit ? "/acb/2.0/operation/update" : "/acb/2.0/operation/add",
        method: this.isEdit ? 'post' : "post",
        data: tempData,
        success: (res) => {
          if (this.image.length === 0) {
            this.loading = false;
            // this.$router.push({ path: '/operatorInfoManage' })
            this.$router.go(-1);
            return;
          }
          let commonKey = "";
          if (this.isEdit) {
            commonKey = this.operationId;
          } else {
            commonKey = res.value;
          }
          this.uploadImage(commonKey);
        },
        fail: (res) => {
          this.loading = false;
          this.$message.error("提交表单失败!");
        },
      };
      this.$request(opt);
    },
    // 上传图片
    uploadImage(commonKey) {
      const image = new FormData();
      this.image.forEach((item, index) => {
        image.append("image", item, "img" + index);
      });
      const opt = {
        url: `/acb/2.0/systems/loginUser/uploadList?imageNo=1&commonKey=${commonKey}`,
        method: "post",
        data: image,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        success: (res) => {
          this.loading = false;
          this.$router.push({ path: "/mechantInfoManage" });
        },
        fail: (err) => {
          this.loading = false;
          this.$message.error("上传图片失败!");
        },
      };
      this.$request(opt);
    },
    // 支付收款帐户字段变动
    handleChange(value) {
      this.settleForm = {};
      this.checkForm = {};
    },
    // 打开清结算弹窗
    checkConfig(type) {
      this.slotType = type;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["settleForm"].clearValidate();
      });
      switch (type) {
        case 1:
          this.slotTitle = "APP (微信支付)";
          break;
        case 2:
          this.slotTitle = "APP (支付宝支付)";
          break;
        case 3:
          this.slotTitle = "微信公众号及小程序";
          break;
        case 4:
          this.slotTitle = "支付宝生活号及小程序";
          break;
        case 5:
          this.slotTitle = "APP (微信支付)";
          break;
        case 6:
          this.slotTitle = "APP (支付宝支付)";
          break;
        case 7:
          this.slotTitle = "微信公众号及小程序";
          break;
        case 8:
          this.slotTitle = "支付宝生活号及小程序";
          break;
        default:
          break;
      }
    },
    // 临时保存清结算数据
    saveSettleData() {
      this.$refs["settleForm"].validate((valid) => {
        if (valid) {
          this.dialogVisible = false;
          this.checkForm = Object.assign({}, this.settleForm);
        }
      });
    },
    // 处理上传文件
    handleFiles(file) {
      // const fileItem = URL.createObjectURL(file.file)
      this.image.push(file.file);
    },
    // 上传前验证
    beforeUpload(file) {
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isJpg = suffix === "jpg";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJpg) {
        this.$message.warning("图片格式错误，只支持jpg格式!");
        return false;
      }
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5M!");
        return false;
      }
      return true;
    },
    // 处理文件变化回调
    handleOnChange(file, fileList) {
      this.hideUploadEdit = fileList.length >= 1;
    },
    // 删除上传文件
    handleRemove(file, fileList) {
      console.log(fileList, "fileList");
      this.fileList = fileList;
      this.hideUploadEdit = fileList.length >= 1;
      this.image = [];
    },
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
  text-align right
  margin-bottom 20px
.content
  margin-bottom 20px
  padding 20px
  .title
    font-size 18px
    font-weight bold
    color #999
    padding-bottom 20px
.config-row
  text-align center
  line-height 4em
.slot-title
  font-size 16px
  font-weight bold
  text-align center
  color #606266
.copy-btn
  color #0f6eff
  cursor pointer
>>>.hide
  .el-upload--picture-card
    display none
</style>
